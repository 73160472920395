import { createTheme, responsiveFontSizes } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

const baseTheme = createTheme({
  palette: {
    primary: { main: "#1E222A" },
    secondary: { main: "#FF505E" },
  },
  spacing: (factor: number) => `${0.25 * factor}rem`,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1450,
      xxl: 1600,
    },
  },
});

const responsiveTheme = responsiveFontSizes(baseTheme);

export default responsiveTheme;
