"use client";

import { FC, PropsWithChildren } from "react";
import theme from "@/styles/theme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { NextAppDirEmotionCacheProvider } from "tss-react/next/appDir";

const NextThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <NextAppDirEmotionCacheProvider options={{ key: "css" }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </NextAppDirEmotionCacheProvider>
  );
};

export default NextThemeProvider;
