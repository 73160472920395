import axios from "axios";
import { v4 as uuidv4 } from "uuid";

export interface ImageTracker {
  init: (identifier?: string) => void;
  getViewedImages: () => Array<{
    resource_name: string;
    resource_identifier: string;
  }>;
  getLastClickedImage: () => {
    resource_name: string;
    resource_identifier: string;
  } | null;
}

interface TrackedImage {
  resource_name: string;
  resource_identifier: string;
}

class ImageTrackerImpl implements ImageTracker {
  private viewedImages: TrackedImage[] = [];
  private lastClickedImage: TrackedImage | null = null;
  private observer: IntersectionObserver | null = null;
  private mutationObserver: MutationObserver | null = null;
  private identifier = "track-resource";
  private readonly MAX_VIEWED_IMAGES = 1000;

  init(identifier = "track-resource"): void {
    this.identifier = identifier;

    this.observer = new IntersectionObserver(
      this.handleIntersection.bind(this),
      {
        threshold: 1.0,
        rootMargin: "0px",
      }
    );

    this.mutationObserver = new MutationObserver(
      this.handleDOMMutation.bind(this)
    );
    this.mutationObserver.observe(document.body, {
      childList: true,
      subtree: true,
    });

    this.trackExistingImages();
  }

  private handleIntersection(entries: IntersectionObserverEntry[]): void {
    entries.forEach((entry) => {
      if (
        entry.isIntersecting &&
        entry.intersectionRatio === 1 &&
        entry.target instanceof HTMLElement
      ) {
        this.addToViewedImages(entry.target);
      }
    });
  }

  private handleDOMMutation(mutations: MutationRecord[]): void {
    for (const mutation of mutations) {
      if (mutation.type === "childList") {
        mutation.addedNodes.forEach((node) => {
          if (node instanceof HTMLElement) {
            this.trackNewImage(node);
          }
        });
      }
    }
  }

  private trackExistingImages(): void {
    document
      .querySelectorAll<HTMLElement>(`[data-${this.identifier}]`)
      .forEach(this.trackNewImage.bind(this));
  }

  private trackNewImage(element: HTMLElement): void {
    if (element.hasAttribute(`data-${this.identifier}`)) {
      this.observer?.observe(element);
      element.addEventListener("click", this.handleImageClick.bind(this));
    } else {
      element
        .querySelectorAll<HTMLElement>(`[data-${this.identifier}]`)
        .forEach((img) => {
          this.observer?.observe(img);
          img.addEventListener("click", this.handleImageClick.bind(this));
        });
    }
  }

  private addToViewedImages(element: HTMLElement): void {
    const resourceIdentifier = element.getAttribute("data-resource-id");
    const resourceName = element.getAttribute("data-resource-name");
    if (
      resourceIdentifier &&
      resourceName &&
      !this.viewedImages.some(
        (img) => img.resource_identifier === resourceIdentifier
      )
    ) {
      if (this.viewedImages.length >= this.MAX_VIEWED_IMAGES) {
        this.viewedImages.shift(); // Remove the oldest image
      }
      this.viewedImages.push({
        resource_name: resourceName,
        resource_identifier: resourceIdentifier,
      });
    }
  }

  private async handleImageClick(event: Event): Promise<void> {
    if (event.currentTarget instanceof HTMLElement) {
      const resourceIdentifier =
        event.currentTarget.getAttribute("data-resource-id");
      const resourceName =
        event.currentTarget.getAttribute("data-resource-name");
      if (resourceIdentifier && resourceName) {
        this.lastClickedImage = {
          resource_name: resourceName,
          resource_identifier: resourceIdentifier,
        };
        this.sendImageClickEvent(this.lastClickedImage).then(
          () => (this.viewedImages = [])
        );
      }
    }
  }

  private async sendImageClickEvent(clickedImage: TrackedImage): Promise<void> {
    const eventData = {
      type: "interaction",
      message_id: uuidv4(),
      resource_name: clickedImage.resource_name,
      resource_identifier: clickedImage.resource_identifier,
      payload: {
        action: "click",
        payload: {
          not_clicked: this.viewedImages,
        },
      },
    };

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL}/events/publish`,
        eventData,
        config
      );
    } catch (error) {
      console.error("Error sending image click event:", error);
    }
  }

  getViewedImages(): Array<{
    resource_name: string;
    resource_identifier: string;
  }> {
    return this.viewedImages;
  }

  getLastClickedImage(): {
    resource_name: string;
    resource_identifier: string;
  } | null {
    return this.lastClickedImage;
  }
}

export const imageTracker: ImageTracker = new ImageTrackerImpl();
