"use client";

import {
  PropsWithChildren,
  useEffect,
  useState,
  createContext,
  Dispatch,
} from "react";
import { usePathname } from "next/navigation";

type HistoryContextType = {
  history: string[];
  setHistory: Dispatch<string[]>;
};

export const HistoryContext = createContext<HistoryContextType>({
  history: [],
  setHistory: () => {},
});

export const HistoryProvider = ({ children }: PropsWithChildren) => {
  const pathname = usePathname() as string;
  const [history, setHistory] = useState<string[]>([]);

  useEffect(() => {
    setHistory((prev) => {
      const newState = [...prev, pathname];
      if (newState.length > 10) {
        newState.shift();
      }
      return newState;
    });
  }, [pathname]);

  return (
    <HistoryContext.Provider value={{ history, setHistory }}>
      {children}
    </HistoryContext.Provider>
  );
};
