"use client";

import { FC, PropsWithChildren, useRef } from "react";
import { Provider } from "react-redux";
import { PreloadedState } from "@reduxjs/toolkit";

import { makeStore, AppStore, RootState } from "./store";

interface ReduxProviderProps extends PropsWithChildren {
  initialState?: PreloadedState<RootState>;
}

const ReduxProvider: FC<ReduxProviderProps> = ({ children, initialState }) => {
  const storeRef = useRef<AppStore>();
  if (!storeRef.current) {
    storeRef.current = makeStore(initialState);
  }

  return <Provider store={storeRef.current}>{children}</Provider>;
};

export default ReduxProvider;
